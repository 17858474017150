import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import MainLayout from "../components/layouts/MainLayout";
import InitialBooking from "../components/views/BookingList";
import CheckedInBooking from "../components/views/CheckedInBooking";
import ModalError from "../components/ModalError";
import Spinner from "../components/Spinner";
import { bookingListAction } from "../stores/actions";
import * as api from "../api/sanha";
import { useNavigate, useSearchParams } from "react-router-dom";
import { RootState } from "../stores/reducers";
import * as GoogleAnalytics from "../lib/google-analytics";
import * as Sentry from "@sentry/react";
import { logDataApi } from "../lib/logDataApi";
import { notification } from "../api/server";

interface urlQueryStringParams {
  rsvnNo?: string | null;
  rsvnSeqNo?: string | null;
}

type Props = JSX.IntrinsicAttributes & { children?: React.ReactNode };

const InitialBookingContainer = (props: Props) => {
  const navigation = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const urlQueryStringParams: urlQueryStringParams = {
    rsvnNo: searchParams.get("rsvnNo"),
    rsvnSeqNo: "1",
  };
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenModalError, setIsOpenModalError] = useState(false);
  const [modalErrorMessage, setModalErrorMessage] = useState("");
  const [modalErrorSubMessage, setModalErrorSubMessage] = useState("");
  const { bookingItem, roomInfo } = useSelector((state: RootState) => state.bookingList);
  const dispatch = useDispatch();

  const openModalError = useCallback(() => {
    setIsOpenModalError(true);
  }, []);

  const closeModalError = useCallback(() => {
    setIsOpenModalError(false);
  }, []);

  const listBooking = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await api.listBooking(urlQueryStringParams);
      if (response.code === "3025") {
        GoogleAnalytics.customEvent({
          category: "api_response",
          action: "api_response_fail_3025_list_booking",
          label: `${response.code}_${response.message}`,
        });
        frontPage();
      } else if (response.code !== "0000") {
        GoogleAnalytics.customEvent({
          category: "api_response",
          action: "api_response_fail_list_booking",
          label: `${response.code}_${response.message}`,
        });

        Sentry.captureMessage(`listBooking_Error : ${response.code}: ${response.message} (예약번호:${bookingItem.rsvnNo})`, "error");
        setModalErrorMessage("예약 조회에 실패 하였습니다.");
        throw new Error(`${response.code}, ${response.message}`);
      }
      if (response.data) {
        GoogleAnalytics.customEvent({
          category: "api_response",
          action: "api_response_ok_list_booking",
          label: `${response.data.rsvnNo}_${response.data.rsvnStatusCode}`,
        });
        dispatch(bookingListAction.setBookingItem(response.data));
        if (!["5", "6"].includes(response.data.caclPayCode)) frontPage();
        if (response.data.rsvnStatusCode !== "RR")
          await selectCheckedInBooking({
            folioNo: response.data.folioNo,
          });
        //Log 전송 여부 확인
        if (response.data.rsvnStatusCode === "RR") logDataApi({ rsvnNo: response.data.rsvnNo, progress: "0", ref: "Y" });
      } else {
        dispatch(bookingListAction.setBookingItem({}));
        dispatch(bookingListAction.setRoomInfo({}));
      }
    } catch (error: any) {
      dispatch(bookingListAction.setBookingItem({}));
      dispatch(bookingListAction.setRoomInfo({}));

      //Log 전송 여부 확인
      if (urlQueryStringParams.rsvnNo) logDataApi({ rsvnNo: urlQueryStringParams.rsvnNo, progress: "0", ref: "N" });

      setModalErrorMessage(error.response?.data?.message);
      setModalErrorSubMessage(error.message);
      Sentry.captureMessage(`listBooking_Error : ${error.response?.data?.message} || ${error.message} (예약번호:${bookingItem.rsvnNo})`);
      openModalError();
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, urlQueryStringParams]);

  const selectCheckedInBooking = useCallback(async ({ folioNo }: api.folioNoProps) => {
    try {
      const response = await api.selectCheckedInBooking({ folioNo });
      if (response.code !== "0000") {
        GoogleAnalytics.customEvent({
          category: "api_response",
          action: "api_response_fail_select_inhouse",
          label: `${response.code}_${response.message}`,
        });

        Sentry.captureMessage(`selectCheckedInBooking_Error : ${response.code}: ${response.message} (예약번호:${bookingItem.rsvnNo})`);
        setModalErrorMessage("투숙 조회에 실패 하였습니다.");
        throw new Error(`${response.code}, ${response.message}`);
      }
      if (response.data && response.data) {
        GoogleAnalytics.customEvent({
          category: "api_response",
          action: "api_response_ok_select_inhouse",
        });
        dispatch(bookingListAction.setRoomInfo(response.data));
      }
    } catch (error: any) {
      dispatch(bookingListAction.setRoomInfo({}));
      setModalErrorMessage("프론트를 방문해주세요.");
      setModalErrorSubMessage(error.message);
      Sentry.captureMessage(
        `selectCheckedInBooking_Error : ${error.response?.data?.message} || ${error.message} (예약번호:${bookingItem.rsvnNo})`
      );
      openModalError();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkOut = async () => {
    try {
      setIsLoading(true);
      const checkOutAmountResponse = await api.confirmCheckOutAmount({
        folioNo: bookingItem.folioNo || roomInfo.folioNo,
      });
      if (checkOutAmountResponse.code !== "0000" && checkOutAmountResponse.code !== "3024") {
        GoogleAnalytics.customEvent({
          category: "api_response",
          action: "api_response_fail_confirm_checkout_amount",
          label: `${checkOutAmountResponse.code}_${checkOutAmountResponse.message}`,
        });

        Sentry.captureMessage(
          `checkOut_Error : ${checkOutAmountResponse.code}: ${checkOutAmountResponse.message} (예약번호:${bookingItem.rsvnNo})`
        );
        setModalErrorMessage("퇴실 잔액 조회에 실패 하였습니다.");
        throw new Error(`${checkOutAmountResponse.code}, ${checkOutAmountResponse.message}`);
      }
      if (checkOutAmountResponse.data.balanceExist === "Y" && checkOutAmountResponse.data.balanceAmount > 0) {
        GoogleAnalytics.customEvent({
          category: "api_response",
          action: "api_response_fail_confirm_checkout_amount",
          label: `${checkOutAmountResponse.code}_${checkOutAmountResponse.data.balanceExist}_${checkOutAmountResponse.data.balanceAmount}`,
        });

        Sentry.captureMessage(`checkOut_Amount_Error : 프론트로 퇴실 처리 문의 바랍니다. (예약번호:${bookingItem.rsvnNo})`);
        setModalErrorMessage("결제 금액이 남아 있어, 체크아웃에 실패 하였습니다.");
        throw new Error("프론트로 퇴실 처리 문의 바랍니다.");
      } else {
        GoogleAnalytics.customEvent({
          category: "api_response",
          action: "api_response_ok_confirm_checkout_amount",
        });
        const checkOutResponse = await api.checkOut({
          folioNo: bookingItem.folioNo || roomInfo.folioNo,
          earlyCheckoutYN: "N",
        });
        if (checkOutResponse.code !== "0000") {
          GoogleAnalytics.customEvent({
            category: "api_response",
            action: "api_response_fail_checkout_request",
            label: `${checkOutResponse.code}_${checkOutResponse.message}`,
          });
          logDataApi({ rsvnNo: bookingItem.rsvnNo, progress: "6", ref: "N" }); //Log 체크인 확인
          setModalErrorMessage("체크아웃에 실패 하였습니다.");
          if (checkOutResponse.code === "3011") {
            Sentry.captureMessage(
              `checkOut_Amount_Error : 퇴실일자를 확인하시기 바랍니다. 체크아웃은 당일만 가능합니다. (예약번호:${bookingItem.rsvnNo})`
            );
            throw new Error("퇴실일자를 확인하시기 바랍니다. 체크아웃은 당일만 가능합니다.");
          } else {
            Sentry.captureMessage(
              `checkOut_response_Error : ${checkOutResponse.code}: ${checkOutResponse.message} (예약번호:${bookingItem.rsvnNo})`
            );
            throw new Error(`${checkOutResponse.code}, ${checkOutResponse.message}`);
          }
        }

        // 알림 API 호출, 에러 발생해도 무시하고 진행
        try {
          await notification({
            reservation_no: bookingItem.rsvnNo,
            reservation_status: "CO",
            room_no: bookingItem.roomNo,
          });
        } catch (error) {
          console.error("notification 에러 발생:", error);
        }

        GoogleAnalytics.customEvent({
          category: "api_response",
          action: "api_response_ok_checkout_request",
        });
        logDataApi({ rsvnNo: bookingItem.rsvnNo, progress: "6", ref: "Y" }); //Log 체크인 확인
        navigation("/checkout/payment/success");
      }
    } catch (error: any) {
      logDataApi({ rsvnNo: bookingItem.rsvnNo, progress: "6", ref: "N" }); //Log 체크인 확인
      setModalErrorMessage("프론트를 방문해주세요.");
      setModalErrorSubMessage(error.message);
      openModalError();
    } finally {
      setIsLoading(false);
    }
  };

  const frontPage = () => {
    navigation("/frontInfo");
  };

  useEffect(() => {
    //if (!_.isEmpty(urlQueryStringParams)) dispatch(bookingListAction.setBookingQueryStringParams(urlQueryStringParams));
    listBooking();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (bookingItem.rsvnNo && bookingItem.rsvnStatusCode === "RR") {
      GoogleAnalytics.customEvent({
        category: "page_view",
        action: "page_view_booking_info",
        label: `${bookingItem.rsvnNo}_${bookingItem.rsvnStatusCode}_${bookingItem.guestName}`,
      });
    } else if (bookingItem.rsvnNo && bookingItem.rsvnStatusCode !== "RR") {
      GoogleAnalytics.customEvent({
        category: "page_view",
        action: "page_view_stay_info",
        label: `${bookingItem.rsvnNo}_${bookingItem.rsvnStatusCode}_${roomInfo.inhsGestName || bookingItem.guestName}`,
      });
    }
  }, [bookingItem.guestName, bookingItem.rsvnNo, bookingItem.rsvnStatusCode, roomInfo.inhsGestName]);

  return (
    <div {...props}>
      {!_.isEmpty(bookingItem) && bookingItem.rsvnStatusCode === "CI" ? ( // 체크인, 투숙 조회 api 완료되면 원복
        <MainLayout
          customStyle={{ position: "relative", margin: 0 }}
          ContentBody={<CheckedInBooking bookingItem={{ ...bookingItem, ...roomInfo }} checkOut={checkOut} />}
        />
      ) : (
        <MainLayout
          ContentBody={
            <InitialBooking
              bookingItem={bookingItem}
              setModalErrorMessage={setModalErrorMessage}
              setModalErrorSubMessage={setModalErrorSubMessage}
              openModalError={openModalError}
            />
          }
        />
      )}
      <ModalError isOpen={isOpenModalError} message={modalErrorMessage} subMessage={modalErrorSubMessage} onClose={closeModalError} />
      <Spinner isLoading={isLoading} />
    </div>
  );
};

export default InitialBookingContainer;
