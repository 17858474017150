import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import moment from "moment";
import MainLayout from "../components/layouts/MainLayout";
import RoomSelect from "../components/views/RoomSelect";
import ModalError from "../components/ModalError";
import Spinner from "../components/Spinner";
import * as api from "../api/sanha";
import * as serverApi from "../api/server";
import { useNavigate, useSearchParams } from "react-router-dom";
import queryString from "query-string";
import * as Sentry from "@sentry/react";
import { bookingListAction } from "../stores/actions";
import { logDataApi } from "../lib/logDataApi";

type Props = JSX.IntrinsicAttributes & { children?: React.ReactNode };

const RoomSelectContainer = (props: Props) => {
  const navigation = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const urlQueryStringParams: any = queryString.parse(searchParams.toString());
  const [isLoading, setIsLoading] = useState(false);
  const [totalRoomList, setTotalRoomList] = useState<api.listAvailableRoomServerApi[]>([]); //선택 가능한 객실 모든 정보
  const [roomList, setRoomList] = useState<api.listAvailableRoomServerApi[]>([]); //선택 가능한 객실 목록
  const [buildingCodeList, setBuildingCodeList] = useState<any[]>([]); //선택 가능한 빌딩 코드 목록
  const [buildingNameList, setBuildingNameList] = useState<any[]>([]); //선택 가능한 빌딩 이름 목록
  const [floorCodeList, setFloorCodeList] = useState<any[]>([]); //선택 가능한 층 코드 목록
  const [floorNameList, setFloorNameList] = useState<any[]>([]); //선택 가능한 층 이름 목록
  const [selectedBuilding, setSelectedBuilding] = useState<string>(""); //선택한 빌딩
  const [selectedFloor, setSelectedFloor] = useState<string>(""); //선택한 층
  const [selectedRoom, setSelectedRoom] = useState<any>({}); //선택한 객실에 대한 모든 정보
  const [isOpenModalError, setIsOpenModalError] = useState(false);
  const [isErrorRefresh, setIsErrorRefresh] = useState(false);
  const [modalErrorMessage, setModalErrorMessage] = useState("");
  const [modalErrorSubMessage, setModalErrorSubMessage] = useState("");
  const { bookingItem, userInfo } = useSelector((state: any) => state.bookingList);
  const dispatch = useDispatch();

  const isTestMode = process.env.REACT_APP_ENV === "production" ? false : true;

  const openModalError = () => {
    setIsOpenModalError(true);
  };

  const closeModalError = () => {
    setIsOpenModalError(false);
    if (isErrorRefresh) {
      window.location.reload();
    }
  };

  const registCheckInPayment = useCallback(async () => {
    try {
      if (!urlQueryStringParams || urlQueryStringParams.responseCode !== "0000") {
        Sentry.captureMessage(`registCheckInPayment_Error : 결제 요청에 실패 하였습니다. (예약번호:${bookingItem.rsvnNo})`);
        setModalErrorMessage("결제 요청에 실패 하였습니다.");
        throw new Error("");
      } else {
        Sentry.captureMessage(`payment_room_page_success : 결제 완료 (예약번호:${bookingItem.rsvnNo} || ${bookingItem.caclPayCode})`);
        const response = await api.registCheckInPayment({
          rsvnNo: bookingItem.rsvnNo,
          rsvnSeqNo: "1",
          trnsNo: urlQueryStringParams.shopOrderNo, //거래 승인 번호
          intApprTypeCode: "P", //인터넷 결제 유형 코드
          intPayStatusCode: 11, //인터넷 결제 상태 코드
          payFnshDate: moment().format("YYYYMMDD"), //인터넷 지불 일자
          successYn: urlQueryStringParams.successYn,
          trnsDate: moment().format("YYYYMMDD"), //거래일자
          trnsHHMMSS: moment().format("HHmmss"),
          trnsAmt: urlQueryStringParams.trnsAmt,
          cardMonthCnt: urlQueryStringParams.cardMonthCnt || "", //할부 개월 수
          cardApprNo: urlQueryStringParams.trnsNo || "", //카드 승인 번호 (간편결제 시 null이라서 pgCno 값 적용)
          cardName: urlQueryStringParams.cardName || "", //카드명
          cardIssueCode: urlQueryStringParams.cardIssueCode || "", //카드 발급사 코드
          cardPurcCode: urlQueryStringParams.cardPurcCode || "", //카드 매입사 코드
          trnsBankCode: "", //거래 은행 코드
          acctNo: "", //계좌번호
          depositor: urlQueryStringParams.depositor || "", //예금주명
          cashRcptTypeCode: 0, //현금 영수증 발급 구분 코드
          cashRcptApptNo: "", //현금 영수증 승인 번호
          cashRcptCxlApptNo: "", //현금 영수증 취소 승인 번호
          responseCode: urlQueryStringParams.responseCode || "",
          message1: urlQueryStringParams.responseMessage || "",
          message2: "",
          dpsitPayDivCode: "01",
          userId: "KEYLESS",
          userIp: "127.0.0.0",
          payMgtNo: "",
          dpsitPayNo: "",
          outYN: "",
          outMsg: "",
        });
        if (response.code !== "0000") {
          Sentry.captureMessage(
            `registCheckInPayment_response_Error : ${response.code}: ${response.message} (예약번호:${bookingItem.rsvnNo})`
          );
          setModalErrorMessage("결제는 성공하였으나, 이 후 처리 과정에서 문제가 생겼습니다.");
          throw new Error(`${response.code}, ${response.message}`);
        } else {
          if (bookingItem.roomNo) await checkIn();
          else await listAvailableRoom(); // 위 예약 결제 내역 등록 api 완료되면 원복
          dispatch(
            bookingListAction.setPaymentInfo({
              rsvnNo: bookingItem.rsvnNo,
              isPaid: true,
            })
          );
          navigation("/room");
        }
      }
    } catch (error: any) {
      Sentry.captureMessage(
        `registCheckInPayment_response2_Error : ${error.response?.data?.message}: ${error.message} (예약번호:${bookingItem.rsvnNo})`
      );
      setModalErrorMessage("프론트를 방문해주세요.");
      setModalErrorSubMessage(error.message);
      openModalError();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const listAvailableRoom = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await api.listAvailableRoom({
        rsvnNo: bookingItem.rsvnNo,
        rsvnSeqNo: "1",
      });
      if (response.code !== "0000" && !response.data) {
        Sentry.captureMessage(`listAvailableRoom_Error : ${response.code}: ${response.message} (예약번호:${bookingItem.rsvnNo})`);
        setModalErrorMessage("사용 가능 객실 조회에 실패 하였습니다.");
        throw new Error(`${response.code}, ${response.message}`);
      }
      if (response.data) {
        const floorCode = response.data.map((item) => item.floorCode);
        const floorName = response.data.map((item) => item.floorName);
        const buildingCode = response.data.map((item) => item.zoneTypeCode);
        const buildingName = response.data.map((item) => item.zoneTypeName);

        setBuildingCodeList(buildingCode.filter((code, i) => buildingCode.indexOf(code) === i));
        setBuildingNameList(buildingName.filter((code, i) => buildingName.indexOf(code) === i));
        setFloorCodeList(floorCode.filter((code, i) => floorCode.indexOf(code) === i));
        setFloorNameList(floorName.filter((code, i) => floorName.indexOf(code) === i));
        setTotalRoomList(response.data);
      }
    } catch (error: any) {
      Sentry.captureMessage(
        `listAvailableRoom_response_Error : ${error.response?.data?.message}: ${error.message} (예약번호:${bookingItem.rsvnNo})`
      );
      setModalErrorMessage("프론트를 방문해주세요.");
      setModalErrorSubMessage(error.message);
      openModalError();
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeBuilding = useCallback(
    (building: string) => {
      setSelectedBuilding(building);
      const roomList = totalRoomList.filter((item) => item.zoneTypeCode === building);
      setRoomList(roomList);
      setSelectedRoom({});
    },
    [totalRoomList]
  );

  const changeFloor = useCallback(
    (floor: string) => {
      setSelectedFloor(floor);
      if (selectedBuilding) {
        const roomList = totalRoomList.filter((item) => item.zoneTypeCode === selectedBuilding && item.floorCode === floor);
        setRoomList(roomList);
        setSelectedRoom({});
      }
    },
    [selectedBuilding, totalRoomList]
  );

  const changeRoom = useCallback(
    (room: api.listAvailableRoomServerApi) => {
      if (selectedRoom.roomNo === room.roomNo) return;
      setSelectedRoom(room);
    },
    [selectedRoom]
  );

  const getkakaoUserData = async () => {
    try {
      await serverApi.kakaoUserData({
        hotel_id: userInfo.hotel_id,
        account_email: userInfo.email,
        name: userInfo.name,
        gender: userInfo.gender,
        age_range: userInfo.age_range,
        birthday: userInfo.birthday,
        birthyear: userInfo.birthyear,
        phone_number: userInfo.phone_number,
      });
    } catch (error) {
      Sentry.captureMessage(`getkakaoUserData_Error : ${error} (예약번호:${bookingItem.rsvnNo})`);
    }
  };

  const roomCheck = async () => {
    try {
      const response = await api.listAvailableRoom({
        rsvnNo: bookingItem.rsvnNo,
        rsvnSeqNo: "1",
      });

      if (response.data.filter((item) => item.roomNo === bookingItem.roomNo || item.roomNo === selectedRoom.roomNo).length > 0) {
        return true;
      }

      setModalErrorSubMessage("해당 객실은 이미 선택이 완료된 객실입니다. \n 새로고침 후 다시 선택해주세요.");
      setIsErrorRefresh(true);
      openModalError();
      return false;
    } catch (error: any) {
      return true;
    }
  };

  const checkIn = async () => {
    try {
      setIsLoading(true);
      if (!bookingItem.rsvnNo) throw new Error("체크인을 할 수 없습니다.");
      const checkFlag = await roomCheck();

      if (checkFlag) {
        const response = await api.checkIn({
          rsvnNo: bookingItem.rsvnNo,
          rsvnSeqNo: "1",
          roomNo: bookingItem.roomNo || selectedRoom.roomNo,
        });
        if (response.code !== "0000") {
          Sentry.captureMessage(
            `checkIn_Error : ${response.code}: ${response.message} (예약번호:${bookingItem.rsvnNo} : ${bookingItem.roomNo} : ${selectedRoom.roomNo})`
          );
          setModalErrorMessage("체크인에 실패 하였습니다.");
          throw new Error(`${response.code}, ${response.message}`);
        }

        if (!isTestMode) getkakaoUserData();

        // 알림 API 호출, 에러 발생해도 무시하고 진행
        try {
          await serverApi.notification({
            reservation_no: bookingItem.rsvnNo,
            reservation_status: "CI",
            room_no: bookingItem.roomNo || selectedRoom.roomNo,
          });
        } catch (error) {
          console.error("notification 에러 발생:", error);
        }

        logDataApi({ rsvnNo: bookingItem.rsvnNo, progress: "3", ref: "Y" }); //Log 체크인 확인
        Sentry.captureMessage(`checkIn_success : 체크인 완료 (예약번호:${bookingItem.rsvnNo} || ${bookingItem.caclPayCode})`);
        dispatch(bookingListAction.setBookingQueryStringParams("")); //체크인 완료 시 QueryString 초기화
        navigation(`/?rsvnNo=${bookingItem.rsvnNo}`); //위 체크인 api 완료되면 원복
      }
    } catch (error: any) {
      logDataApi({ rsvnNo: bookingItem.rsvnNo, progress: "3", ref: "N" }); //Log 체크인 확인
      Sentry.captureMessage(
        `checkIn_response_Error : ${error.response?.data?.message}: ${error.message} (예약번호:${bookingItem.rsvnNo} : ${bookingItem.roomNo} : ${selectedRoom.roomNo})`
      );
      setModalErrorMessage("프론트를 방문해주세요.");
      setModalErrorSubMessage(error.message);
      openModalError();
      dispatch(bookingListAction.setPaymentInfo({ rsvnNo: "", isPaid: false })); //체크인 실패 시 산하에서 결제내역을 삭제해 준다고 함.
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    //if (!_.isEmpty(urlQueryStringParams)) registCheckInPayment(); //추후 삭제 생각해 봐야함.
    if (bookingItem.roomNo) checkIn(); //roomNo가 있을 경우 자동 체크인 배정.
    else listAvailableRoom();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div {...props}>
      <MainLayout
        customStyle={{ position: "relative", margin: 0 }}
        ContentBody={
          <RoomSelect
            totalRoomList={totalRoomList}
            buildingCodeList={buildingCodeList}
            buildingNameList={buildingNameList}
            floorCodeList={floorCodeList}
            floorNameList={floorNameList}
            roomList={roomList}
            selectedRoom={selectedRoom}
            selectedBuilding={selectedBuilding}
            selectedFloor={selectedFloor}
            changeBuilding={changeBuilding}
            changeFloor={changeFloor}
            changeRoom={changeRoom}
            checkIn={checkIn}
          />
        }
      />
      <ModalError isOpen={isOpenModalError} message={modalErrorMessage} subMessage={modalErrorSubMessage} onClose={closeModalError} />
      <Spinner isLoading={isLoading} />
    </div>
  );
};

export default RoomSelectContainer;
